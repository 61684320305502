:root {
  --app-padding: 20px;
  --primary-color: #0D99FF;
}

@font-face {
  font-family: 'Soehne Mono';
  src: url('../fonts/soehne-mono-buch.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Soehne';
  src: url('../fonts/soehne-buch.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Soehne', sans-serif;
}

.App {
  font-family: 'Soehne', sans-serif;
  padding: var(--app-padding);
  max-width: 1024px;
  margin: 0 auto;
  box-sizing: border-box;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -10px; 
}

.left-section, .right-section {
  width: 100%;
}

.dropdowns-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px; 
}

.dropdowns-row select {
  width: 32%; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
  padding-right: 30px;
}

.dropdowns-row select[name="Specials"] {
  font-size: 13px; 
}

.input-group {
  margin-bottom: 8px; 
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.input-example {
  display: block;
  font-size: 0.7rem;
  color: #666;
  margin-top: 2px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  margin-top: 5px;
  height: auto;
  max-height: calc(6 * (40px + 8px)); 
  overflow-y: auto;
  padding-right: 8px;
  align-content: start;
}

.popular-categories-label {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 8px;
  margin-top: 12px;
  font-weight: bold;
}

.category-button {
  padding: 8px 4px;
  border: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  text-align: center;
  transition: all 0.3s ease;
  opacity: 1 !important;
  height: 45px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-button.faded {
  opacity: 0.3 !important; 
}

.category-button.selected {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  opacity: 1 !important;
}

.categories-filter {
  margin-bottom: 10px;
  width: 100%; 
}

.categories-filter h3 {
  margin-bottom: 5px;
  font-size: 1.3rem;
}

.category-label {
  display: flex;
  align-items: center;
  margin-bottom: 2px; 
  font-size: 0.8rem; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  font-weight: bold;
}

.category-label.base_meat { color: #FF7534; }
.category-label.cuisine { color: #421327; }
.category-label.base_non_meat { color: #284F27; }
.category-label.flavour { color: #5F4626; }
.category-label.season { color: #FFA699; }
.category-label.preference { color: #C7AD94; }
.category-label.dessert { color: #7a7554; }

.submit-button:disabled {
  background-color: #7fc5ff;
  cursor: not-allowed;
}

.header {
  text-align: center;
  margin-bottom: 10px; 
}

.header .logo {
  max-width: 150px;
  height: auto;
}

.header h1 {
  font-family: 'Soehne', monospace;
  margin-top: 5px;
  font-size: 20px; 
}

.right-bottom {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: -15px;
}

.categories-filter, .submit-button {
  flex: 1;
}

.submit-button {
  margin-left: 20px; 
  height: 80%; 
}

.footer {
  text-align: center;
  margin-top: 4px; 
  position: relative; 
  bottom: 3px; 
}

.footer .chadstone-logo {
  max-width: 200px; 
  height: auto;
}

.logo-container {
  align-self: flex-end;
  margin-top: -60px; 
  margin-bottom: 5px; 
  text-align: right; 
  z-index: 1; 
}

.logo {
  max-width: 50%;
  height: auto;
  margin: 0 auto; 
}

.right-section {
  flex: 1;
  position: sticky;
  top: var(--app-padding);
  display: flex;
  flex-direction: column;
  padding-top: 0; 
  justify-content: space-between; 
}

.input-column {
  margin-top: -10px; 
}

@media (min-width: 768px) {
  .content-wrapper {
    flex-direction: row;
  }

  .left-section {
    flex: 3;
    margin-right: 20px;
  }

  .right-section {
    flex: 1;
  }
}

@media (max-width: 767px) {
  .button-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .dropdowns-row {
    flex-direction: column;
  }

  .dropdowns-row select {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  :root {
    --app-padding: 30px;
  }
  
  .App {
    max-width: 1200px;
  }
}

@media (min-width: 1440px) {
  .App {
    max-width: 1400px;
  }
}

@media (min-width: 1920px) {
  :root {
    --app-padding: 50px;
  }

  .App {
    max-width: 1800px;
    padding: var(--app-padding);
  }

  .content-wrapper {
    flex-direction: row;
    gap: 60px;
    margin-top: 20px;
  }

  .left-section {
    flex: 3;
    margin-right: 60px;
  }

  .right-section {
    flex: 1;
  }

  .button-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 25px;
    max-height: calc(6 * (70px + 25px));
  }

  .category-button {
    font-size: 1.4rem;
    height: 75px;
    padding: 25px 20px;
  }

  .dropdowns-row {
    margin-bottom: 25px;
  }

  .dropdowns-row select {
    font-size: 1.2rem;
    padding: 12px;
  }

  .input-group {
    margin-bottom: 25px;
  }

  .input-group label {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .input-group input {
    font-size: 1.3rem;
    padding: 15px;
    height: 55px;
  }

  .header {
    margin-bottom: 30px;
  }

  .header h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .header .logo {
    max-width: 200px;
  }

  .submit-button {
    height: 70px;  
    font-size: 1.5rem;
    border-radius: 25px;
    margin-top: 15px;
  }

  .reset-button {
    height: 50px;  
    font-size: 1.4rem;
    margin-top: 10px;
  }

  .category-label {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  .input-example {
    font-size: 0.9rem;
  }

  .popular-categories-label {
    font-size: 1.2rem;
    margin-bottom: 15px;
    margin-top: 25px;
  }

  .footer {
    margin-top: 0px;
  }

  .footer .chadstone-logo {
    max-width: 300px;
  }

}


@media (min-width: 768px) and (max-width: 1023px) {
  .button-grid {
    gap: 10px;
    max-height: calc(6 * (35px + 10px)); 
  }

  .category-button {
    font-size: 0.8rem;
    height: 35px; 
  }

  .category-button:active {
    transform: scale(0.95);
  }

  .category-button.selected {
    transform: scale(1.03);
  }

  .category-button:not(.selected):hover {
    opacity: 0.9;
  }
}

.button-grid::-webkit-scrollbar {
  width: 8px;
}

.button-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.button-grid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.button-grid::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-select {
  position: relative;
  width: 32%;
}

.custom-select select {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.selected-value {
  display: block;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
  padding-right: 30px;
}

.pdf-viewer {
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.pdf-controls {
  margin-top: 10px;
  text-align: center;
}

.pdf-controls button {
  margin: 0 10px;
  padding: 5px 10px;
}

.qr-code-container {
    margin-top: 20px;
    text-align: center;
}

.qr-code-container p {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
}

.submit-button-container {
  position: relative;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.submit-button {
  width: 100%;
  height: 45px;
  background-color: #0D99FF;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.3rem;
  transition: background-color 0.3s ease;
  margin-left: 20px; 
}

.reset-button {
  width: 100%;
  height: 50px;
  background-color: #0D99FF;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.3rem;
  transition: background-color 0.3s ease;
  margin-left: 20px;
  z-index: 2;  
}

.submit-button:disabled,
.clear-button:disabled {
  background-color: #7fc5ff;
  cursor: not-allowed;
}

.qr-code-container {
  position: absolute;
  top: 20px;
  left: 10px;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 15px;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
}

.qr-code-container canvas {
  max-width: 100%;
  height: auto;
}

.qr-code-container p {
  margin-top: 5px;
  font-size: 12px;
  color: #666;
}

.error-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.error-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 80%;
  width: 300px;
}

.error-content p {
  margin-bottom: 20px;
  font-size: 16px;
  white-space: pre-wrap; 
  word-wrap: break-word;
}

.error-content button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  background-color: #0D99FF;
  color: white;
  border: none;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}

.error-content button:hover {
  background-color: #007acc;
}
